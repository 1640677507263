.back_image_one {
  position: absolute;
  left: 0%;
  top: 20%;
  width: 20%;
  height: 65%;
  object-fit: cover;
  z-index: -1;
  border-radius: 0% 100% 100% 0% / 43% 19% 81% 57%;
  box-shadow: 0 5px 4px rgba(0, 0, 0, 0.5);
}

.back_image_two {
  position: absolute;
  right: 0%;
  top: 30%;
  width: 30%;
  height: 60%;
  object-fit: cover;
  z-index: -1;
  border-radius: 100% 0% 0% 100% / 84% 54% 46% 16%;
  box-shadow: 0 5px 4px rgba(0, 0, 0, 0.5);
}

.btn_back_searching {
  position: absolute;
  left: 10%;
  top: 5%;
}

@media (min-width: 850px) {
  input {
    width: 25em;
  }
  h1 {
    /* font-size: 2rem !important; */
  }
}

@media (min-width: 635px) {
  .btn_back_searching {
    position: absolute;
    left: 10%;
    top: 10%;
  }
}
