@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: 'Sora', sans-serif;
}

.bg-innova-blue {
  background: #00235c;
}

.nav-rounded {
  border-radius: 0 0 0 30px;
  -webkit-border-radius: 0 0 0 30px;
  -moz-border-radius: 0 0 0 30px;
  -ms-border-radius: 0 0 0 30px;
  -o-border-radius: 0 0 0 30px;
}

/* Typography */
.page-title,
.gtp-title,
.input-search,
.textarea-title {
  font-family: 'Caveat Brush', cursive;
}

button {
  font-size: 0.875rem;
}

.innova-title {
  color: #00235c;
  font-size: 2rem;
  line-height: 2.5rem;
}

.innova-text {
  color: #232323;
  font-size: 1.3rem;
  font-style: normal;
  font-weight: 400;
}

.input-search {
  font-size: 1.5rem;
}

.breadcrumb-title {
  font-size: 1.8rem;
  padding: 0.5rem 0rem 0rem 0rem;
  line-height: 1.8rem;
  letter-spacing: 2px;
  color: #00235c;
}

.page-title {
  font-size: 28px;
  padding-bottom: 20px;
  letter-spacing: 2px;
  color: #00235c;
}

.page-subtitle {
  margin: 0;
}

.textarea-title {
  font-size: 20px;
  letter-spacing: 2px;
}

/* Responsive css */

@media (min-width: 1280px) {
  .container {
    max-width: 1200px;
  }
}

@media (max-width: 640px) {
  /* Header Home */
  .header-container {
    flex-direction: column;
    align-items: center;
  }
  .bg-innova-blue,
  nav {
    background: none !important;
  }
  .input-search {
    width: 100%;
  }

  /* Header Other's */
  .header-container-two {
    background-color: #00235c;
    justify-content: space-between;
  }
  .input-search-two {
    width: 50%;
  }
  .container-flex-two {
    width: 12%;
  }
  .fa-search {
    color: white;
    font-size: 25px;
  }

  /* Filtros */
  .filter-container {
    justify-content: center;
    gap: 12px;
    padding: 0;
    margin-bottom: 1em;
  }
  .filter-name {
    font-size: 1rem;
  }
  .Filters_filterButton__BgS0t {
    display: none;
  }

  /* titulos y parrafos */

  .innova-title {
    font-size: 1.25rem;
  }

  .innova-text {
    font-size: 1rem;
    width: 100%;
  }

  .container {
    max-width: 460px;
    padding: 0 10px;
  }
}
