.custom-text {
    text-shadow: 3px 1px 2px rgba(0, 0, 0, 1); /* Sombreado */
    -webkit-text-fill-color: whitesmoke; /* Color del texto */
  }
  
  .bg-opacity {
    background-image: url(./bg_cat404.png);
    background-position: center;
    background-size: cover;
    background-color: rgba(163, 161, 242, 0.8); /* Opacidad de la imagen de fondo */
    background-blend-mode:darken ; /* Mezcla de la imagen de fondo */
  }

  @keyframes fade-in {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  @keyframes slide-up {
    from {
      opacity: 0;
      transform: translateY(100px);
    }
    to {
      opacity: 2;
      transform: translateY(0);
    }
  }
  
  .fade-in {
    animation: fade-in 3s ease forwards;
  }
  
  .slide-up {
    animation: slide-up 3s ease forwards;
  }